import React, { useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const CSVReader = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');
  const [rowsToShow, setRowsToShow] = useState(10); // 表示する行数の初期値を10に設定

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          setData(results.data);
          setFilteredData(results.data); // フィルターなしで全データを表示
        },
        skipEmptyLines: true,
        header: false
      });
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleFilterSubmit = () => {
    applyFilter(filter);
  };

  const applyFilter = (filterString) => {
    const filters = filterString.toLowerCase().replace(/\s|　/g, ' ').split(' ').filter(Boolean);
    if (filters.length === 0) {
      setFilteredData(data);
    } else {
      const filtered = data.filter((row, index) =>
        index === 0 || filters.every(filter =>
          row.some(cell => cell.toString().toLowerCase().includes(filter))
        )
      );
      setFilteredData(filtered);
    }
  };

  const handleRowsToShowChange = (event) => {
    setRowsToShow(Number(event.target.value));
  };

  const downloadFilteredCSV = () => {
    const csv = Papa.unparse(filteredData, {
      encoding: "utf-8"
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'filtered-data.csv';
    link.click();
  };

  const downloadFilteredXLSX = () => {
    // 現行データ構造からヘッダとデータを分けて、Excelに適した形式に変換
    const headers = filteredData[0];
    const dataForXLSX = filteredData.slice(1).map(row => {
      const rowData = {};
      headers.forEach((header, index) => {
        rowData[header] = row[index];
      });
      return rowData;
    });

    const worksheet = XLSX.utils.json_to_sheet(dataForXLSX, { skipHeader: true }); // ヘッダの自動挿入を防ぐ
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Data');
    XLSX.writeFile(workbook, 'filtered-data.xlsx');
  };

  return (
    <div className="container mx-auto p-4">
      <input
        type="file"
        id="csvFileInput"
        accept=".csv"
        onChange={handleFileChange}
        className="mb-4"
      />
      <div className="flex items-center mb-2">
        <input
          type="text"
          placeholder="フィルター（キーワードをスペースで区切る）..."
          value={filter}
          onChange={handleFilterChange}
          className="border p-2 mr-2 w-full"
        />
        <button onClick={handleFilterSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          フィルター適用
        </button>
        <button onClick={downloadFilteredCSV} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 ml-2 rounded">
          CSVダウンロード
        </button>
        <button onClick={downloadFilteredXLSX} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 ml-2 rounded">
          XLSXダウンロード
        </button>
        <span className="text-sm ml-2">表示行数: {filteredData.length - 1}</span> {/* ヘッダーを除く行数 */}
      </div>
      <input
        type="number"
        placeholder="表示する行数を設定..."
        value={rowsToShow}
        onChange={handleRowsToShowChange}
        className="border p-2 mb-2 w-full"
      />
      {filteredData.length > 0 && (
        <table className="min-w-full divide-y divide-gray-200 mt-2">
          <thead className="bg-gray-200">
            <tr>
              {filteredData[0]?.map((header, index) => (
                <th key={index} className="px-4 py-2 text-left">{header}</th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredData.slice(1, 1 + rowsToShow).map((row, index) => (
              <tr key={index}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="px-4 py-2">{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CSVReader;
