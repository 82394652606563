import React, { useState, useEffect } from "react";

const CSVExtractor = () => {
  const [fileContent, setFileContent] = useState("");
  const [headers, setHeaders] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(new Set());

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => setFileContent(e.target.result);
    reader.readAsText(file);
  };

  useEffect(() => {
    if (fileContent) {
      const lines = fileContent.split("\n");
      const headers = lines[0].split(",");
      setHeaders(headers);
    }
  }, [fileContent]);

  const handleCheckboxChange = (index) => {
    setSelectedColumns((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const downloadCSV = () => {
    const extractedData = extractColumns();
    const blob = new Blob([extractedData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "extracted_columns.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const extractColumns = () => {
    const lines = fileContent.split("\n");
    return lines
      .map((line) => {
        const columns = line.split(",");
        return Array.from(selectedColumns)
          .map((index) => columns[index])
          .join(",");
      })
      .join("\n");
  };

  return (
    <div className="p-5 bg-blue-100 min-h-screen">
      <h1 className="text-xl font-bold text-blue-800 mb-2">
        CSVを読み込み、指定の列のみ抽出して出力するツール
      </h1>
      <p className="mb-4 text-blue-700">
        このツールを使用して、CSVファイルから特定の列を選択し、それを新しいCSVファイルとしてダウンロードします。
      </p>
      <input
        type="file"
        onChange={handleFileChange}
        accept=".csv"
        className="mb-3"
      />
      <div className="flex flex-wrap gap-2 mb-3">
        {headers.map((header, index) => (
          <label key={index} className="flex items-center gap-2 text-blue-800">
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(index)}
            />
            {header}
          </label>
        ))}
      </div>
      <button
        onClick={downloadCSV}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
      >
        Download Extracted CSV
      </button>
    </div>
  );
};

export default CSVExtractor;
