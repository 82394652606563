import "./App.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="Home">
      <div className="max-w-4xl mx-auto p-5">
        <div className="grid grid-cols-2 gap-4">
          <p>
            <Link
              to="/CsvMatchChecker"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              2つの２列csvを統合する前に、片方の値がもう片方の値として含まれているか確認するツール
            </Link>
          </p>
          <p>
            <Link
              to="/csvdensity"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              2つの２列csvを統合して割り算を行い３列csvを作成するツール
            </Link>
          </p>
          <p>
            <Link
              to="/CsvMatcher"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              2つの２列csvを統合して３列csvを作成するツール
            </Link>
          </p>
          <p>
            <Link
              to="/UchiMatsuReader"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Uchi-Matsu-Readerツール
            </Link>
          </p>
          <p>
            <Link
              to="/GeojsonToCsvConverter"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              pointジオメトリを持つgeojsonをcsvに変換するツール
            </Link>
          </p>
          <p>
            <Link
              to="/CSVExtractor"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              CSVを読み込み、指定の列のみ抽出して出力するツール
            </Link>
          </p>
          <p>
            <Link
              to="/CsvColumnReorder"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              csvの列を並び替えるツール
            </Link>
          </p>
          <p>
            <Link
              to="/csvprocesser"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              csvを表示して確認するツール
            </Link>
          </p>
          <p>
            <Link
              to="/txt2csv"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              １列データから必要な行を抽出しCSVを作成するツール
            </Link>
          </p>
          <p>
            <Link
              to="/CSVtoZIPConverter"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              csvファイルをフォルダに入れて圧縮したzipの中のcsvをそれぞれzipファイルにして、zipにまとめてダウンロードするツール
            </Link>
          </p>
          <p>
            <Link
              to="/kmltokmzconverter"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              kmlファイルをフォルダに入れて圧縮したzipの中のKMLをそれぞれkmzファイルにして、zipにまとめてダウンロードするツール
            </Link>
          </p>
          <p>
            <Link
              to="/csvjoin"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              CSVファイルを結合して一つにするツール
            </Link>
          </p>
          <p>
            <Link
              to="/stringprocessor"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              ほぼ同じたくさんの文字列を作成するツール
            </Link>
          </p>
          <p>
            <Link
              to="/downloadfiles"
              className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              URLリストから１秒ごとにダウンロードするツール
            </Link>
          </p>
          <p className="col-span-2 mt-10 mb-2 font-semibold">以下作成中</p>
          <p>
            <Link
              to="/shindoconv"
              className="block bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              気象庁観測地点ごとの震度を、市町村ごとの震度にまとめるツール　P
            </Link>
          </p>
          <p>
            <Link
              to="/conv3rdto2nd"
              className="block bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              3次メッシュを2次メッシュに変換するツール　P
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
