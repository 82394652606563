import React, { useState } from "react";

const CSVProcessor = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    setIsLoading(true); // ローディング状態を開始

    reader.onload = (e) => {
      try {
        const contents = e.target.result;
        const lines = contents.trim().split("\n");
        const output = lines.slice(0, 100).map((line) =>
          line
            .trim()
            .split(",")
            .map((cell) => cell.trim())
        ); // 最初の100行のみを処理
        setData(output);
      } catch (error) {
        console.error("Error reading the CSV file:", error);
        alert(
          "ファイルの読み込みに失敗しました。ファイルのフォーマットを確認してください。"
        );
      } finally {
        setIsLoading(false); // ローディング状態を終了
      }
    };

    reader.onerror = () => {
      console.error("File reading has failed");
      setIsLoading(false);
      alert("ファイルの読み込みに失敗しました。");
    };

    reader.readAsText(file);
  };

  return (
    <div style={{ fontFamily: "Arial, sans-serif" }}>
      <h1>CSVデータ加工</h1>
      <input type="file" onChange={handleFileChange} accept=".csv" />
      {isLoading ? (
        <p>読み込み中...</p>
      ) : (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          {data.map((row, i) => (
            <tr key={i}>
              {row.map((cell, j) => (
                <td
                  key={j}
                  style={{ border: "1px solid #ddd", padding: "8px" }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default CSVProcessor;
