import React, { useState } from 'react';

const ShindoConv = () => {
  const [csvData, setCsvData] = useState([]);
  const replacements = [
    '石川県輪島市',
    '石川県志賀町',
    '石川県七尾市',
    // 他の設定値もここに追加可能
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const processedData = processCSV(text);
        const mergedData = mergeAndFindMaxIntensity(processedData);
        setCsvData(mergedData);
      };
      reader.readAsText(file);
    }
  };

  const processCSV = (csvText) => {
    const lines = csvText.split('\n').slice(1); // Remove header line
    const result = [];
    lines.forEach(line => {
      const [prefecture, intensity, locations] = line.split(',');
      if (!locations) return; // Skip empty lines
      locations.split(' ').forEach(location => {
        if (!location) return;
        let cleanedLocation = `${prefecture}${location.replace('＊', '')}`; // Remove asterisks and prepend prefecture
        cleanedLocation = replaceLocationWithSetting(cleanedLocation);
        if (cleanedLocation === null) return; // Skip this location if it doesn't match any setting
        const numericIntensity = convertIntensityToInt(intensity);
        result.push([cleanedLocation, numericIntensity]);
      });
    });
    return result;
  };

  const replaceLocationWithSetting = (location) => {
    for (let i = 0; i < replacements.length; i++) {
      if (location.startsWith(replacements[i])) {
        return replacements[i];
      }
    }
    return null; // Return null if no replacement found
  };

  const convertIntensityToInt = (intensity) => {
    const mapping = {
      '震度７': 70,
      '震度６強': 65,
      '震度６弱': 60,
      '震度５強': 55,
      '震度５弱': 50,
      '震度４': 40,
      // Add other mappings as necessary
    };
    return mapping[intensity] || 0;
  };

  const mergeAndFindMaxIntensity = (dataArray) => {
    const mergedData = {};
    dataArray.forEach(([location, intensity]) => {
      if (mergedData.hasOwnProperty(location)) {
        mergedData[location] = Math.max(mergedData[location], intensity);
      } else {
        mergedData[location] = intensity;
      }
    });
    return Object.entries(mergedData).map(([location, intensity]) => [location, intensity]);
  };

  const downloadCSV = () => {
    const BOM = "\uFEFF";
    const csvContent = BOM + csvData.map(e => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'processed_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>CSV Data Processor with React</h2>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      <button onClick={downloadCSV} disabled={csvData.length === 0}>Download CSV</button>
      <table border="1" style={{ marginTop: '10px' }}>
        <thead>
          <tr>
            <th>観測点名</th>
            <th>震度</th>
          </tr>
        </thead>
        <tbody>
          {csvData.map(([location, intensity], index) => (
            <tr key={index}>
              <td>{location}</td>
              <td>{intensity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShindoConv;
