import React, { useState } from "react";
import Papa from "papaparse";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import JSZip from "jszip";

function CsvMatchChecker() {
  const [filesA, setFilesA] = useState([]);
  const [fileB, setFileB] = useState(null);
  const [unmatchedValues, setUnmatchedValues] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFilesAChange = (files) => {
    setFilesA([...files]);
  };

  const handleFileChange = (file, setter) => {
    setter(file);
  };

  const processFiles = async () => {
    if (filesA.length > 0 && fileB) {
      setLoading(true);
      NProgress.start();
      const resultsB = await parseFile(fileB);
      const dataB = resultsB.data;
      const valuesB = new Set(dataB.map((row) => row[0]));

      let allUnmatched = {};

      for (const file of filesA) {
        const resultsA = await parseFile(file);
        const dataA = resultsA.data;
        const unmatched = dataA.filter((row) => !valuesB.has(row[0]));
        allUnmatched[file.name] = unmatched;
      }

      setUnmatchedValues(allUnmatched);

      NProgress.done();
      setLoading(false);
    }
  };

  const parseFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: resolve,
        error: reject,
      });
    });
  };

  const downloadUnmatchedValuesAsCsv = (fileName, data) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}_unmatched.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="p-8 max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold text-indigo-800 mb-6">Unmatched CSV Values Checker</h2>
      <div className="space-y-8">
        <div>
          <label htmlFor="filesA" className="block text-sm font-medium text-gray-700">
            Upload CSV files for comparison (multiple files allowed):
          </label>
          <div className="flex items-center space-x-3">
            <input
              id="filesA"
              type="file"
              multiple
              onChange={(e) => handleFilesAChange(e.target.files)}
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <div>
          <label htmlFor="fileB" className="block text-sm font-medium text-gray-700">
            Upload reference CSV file:
          </label>
          <div className="flex items-center space-x-3">
            <input
              id="fileB"
              type="file"
              onChange={(e) => handleFileChange(e.target.files[0], setFileB)}
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <button
          onClick={processFiles}
          disabled={loading}
          className="w-full flex justify-center py-3 px-6 border border-transparent rounded-lg shadow-md text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 disabled:bg-gray-400"
        >
          Process Files
        </button>
        {Object.keys(unmatchedValues).length > 0 && (
          <div className="flex flex-col space-y-4">
            {Object.entries(unmatchedValues).map(([fileName, data], index) => (
              <div key={index} className="border border-gray-300 p-4 rounded-lg">
                <h3 className="text-lg font-bold mb-2">Unmatched Values for {fileName}:</h3>
                <ul>
                  {data.map((value, index) => (
                    <li key={index}>{value.join(", ")}</li>
                  ))}
                </ul>
                <button
                  onClick={() => downloadUnmatchedValuesAsCsv(fileName, data)}
                  className="bg-blue-500 text-white px-4 py-2 mt-4 rounded-lg"
                >
                  Download Unmatched Values as CSV
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default CsvMatchChecker;
