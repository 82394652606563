import React, { useState } from 'react';

const GeoJsonToCsvConverter = () => {
    const [geoJson, setGeoJson] = useState('');
    const [csv, setCsv] = useState('');

    const handleGeoJsonChange = (event) => {
        setGeoJson(event.target.value);
    };

    const convertToCSV = () => {
        try {
            const geojsonData = JSON.parse(geoJson);
            let csvResult = '';
            if (geojsonData && geojsonData.type === 'FeatureCollection' && geojsonData.features) {
                const features = geojsonData.features;
                const headers = ['Latitude', 'Longitude', ...Object.keys(features[0].properties)];
                csvResult += headers.join(',') + '\\n';
                features.forEach(feature => {
                    if (feature.geometry.type === 'Point') {
                        const [longitude, latitude] = feature.geometry.coordinates;
                        const properties = feature.properties;
                        const row = [latitude, longitude, ...headers.slice(2).map(header => properties[header] || '')].join(',');
                        csvResult += row + '\\n';
                    }
                });
            }
            setCsv(csvResult);
        } catch (error) {
            setCsv('Error converting GeoJSON: ' + error);
        }
    };

    return (
        <div className="p-8 bg-blue-50 min-h-screen">
            <h1 className="text-3xl font-bold text-blue-700 mb-2">Pointジオメトリを持つGeoJSONをCSVに変換するツール</h1>
            <p className="text-md text-blue-800 mb-4">
                このツールでは、GeoJSON形式で与えられたPointジオメトリデータをCSV形式に変換します。以下のテキストエリアにGeoJSONデータを入力して、
                「Convert to CSV」ボタンを押してください。変換されたCSVデータは下のテキストエリアに表示されます。
            </p>
            <textarea
                className="border border-blue-300 p-2 w-full h-32 mb-4 shadow-sm"
                placeholder="Enter GeoJSON here..."
                value={geoJson}
                onChange={handleGeoJsonChange}
            ></textarea>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow"
                onClick={convertToCSV}
            >
                Convert to CSV
            </button>
            <textarea
                className="border border-blue-300 p-2 w-full h-32 mt-4 shadow-sm"
                placeholder="Resulting CSV will appear here..."
                value={csv}
                readOnly
            ></textarea>
        </div>
    );
};

export default GeoJsonToCsvConverter;
