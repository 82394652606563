import React, { useState } from "react";

const CSVJoinComponent = () => {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [output, setOutput] = useState("");

  const processData = () => {
    let input1Array = input1.split("\n").map((row) => row.split(","));
    let input2Array = input2.split("\n").map((row) => row.split(","));

    let outputData = input1Array
      .map((row1) => {
        let row2 = input2Array.find((row) => row[0] === row1[0]);
        if (row2) {
          return row1.concat(row2.slice(1)).join(",");
        }
        return null;
      })
      .filter((row) => row);

    setOutput(outputData.join("\n"));
  };

  return (
    <div>
      <div
        id="manual"
        style={{ marginTop: "20px", padding: "10px", border: "1px solid #ddd" }}
      >
        <h2>2つのCSVを結合するツール</h2>
        <p>
          このツールは、2つのCSVデータを結合し、それらを特定の規則で並び替える機能を提供します。具体的な使用方法は以下のとおりです:
        </p>
        <ol>
          <li>
            2つのCSVデータを用意します。それぞれのデータの行数は同じである必要があります。また、それぞれのデータの1列目（最も左の列）には同じ値が含まれている必要がありますが、行の並び順は異なることが可能です。
          </li>
          <li>
            1つ目のCSVデータを上のテキストエリアにコピーアンドペーストします。
          </li>
          <li>
            2つ目のCSVデータを次のテキストエリアにコピーアンドペーストします。
          </li>
          <li>「変換」ボタンをクリックします。</li>
          <li>
            ボタンをクリックすると、2つのCSVデータが結合され、それぞれのデータの1列目の値が一致するように並び替えられます。この結果のデータが下のテキストエリアに表示されます。
          </li>
        </ol>
        <p>
          注意:
          このツールは現在のところエラーチェック機能を持っていません。そのため、無効なデータ（CSV形式でないもの、行数が一致しないもの等）を入力した場合の動作は保証されません。
        </p>
      </div>
      <textarea
        value={input1}
        onChange={(e) => setInput1(e.target.value)}
        placeholder="1つ目のCSVをここに貼り付けてください"
        style={{ width: "100%", height: "200px" }}
      />
      <textarea
        value={input2}
        onChange={(e) => setInput2(e.target.value)}
        placeholder="2つ目のCSVをここに貼り付けてください"
        style={{ width: "100%", height: "200px" }}
      />
      <button
        onClick={processData}
        style={{
          display: "block",
          width: "100%",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        変換
      </button>
      <textarea
        value={output}
        placeholder="結果がここに表示されます"
        readOnly
        style={{ width: "100%", height: "200px" }}
      />
    </div>
  );
};

export default CSVJoinComponent;
