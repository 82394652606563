import React, { useRef, useState } from "react";
import JSZip from "jszip";

const KMLtoKMZConverter = () => {
  const fileInputRef = useRef(null);
  const [isProcessing, setProcessing] = useState(false);

  const convertFiles = () => {
    setProcessing(true);
    const fileInput = fileInputRef.current;
    const zipFile = fileInput.files[0];
    var reader = new FileReader();

    reader.onload = function (e) {
      var zipContent = e.target.result;

      JSZip.loadAsync(zipContent).then(function (zip) {
        var newZip = new JSZip();
        var promises = [];

        zip.forEach(function (relativePath, file) {
          if (file.dir) {
            newZip.folder(relativePath);
          } else {
            promises.push(
              zip
                .file(relativePath)
                .async("blob")
                .then(function (content) {
                  var kmlName = relativePath.replace(".kml", "");
                  var nestedZip = new JSZip();
                  nestedZip.file(kmlName + ".kmz", content, {
                    compression: "DEFLATE",
                  });

                  return nestedZip
                    .generateAsync({ type: "blob", compression: "DEFLATE" })
                    .then(function (nestedContent) {
                      newZip.file(kmlName + ".kmz", nestedContent);
                    });
                })
            );
          }
        });

        Promise.all(promises).then(function () {
          newZip.generateAsync({ type: "blob" }).then(function (content) {
            var link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = "newZip.zip";
            link.click();
            setProcessing(false);
          });
        });
      });
    };

    reader.readAsArrayBuffer(zipFile);
  };

  return (
    <div className="container" style={{ width: "80%", margin: "auto" }}>
      <div className="description">
        <h1>KML to KMZ Converter</h1>
        <p>
          This web page is a tool that converts all KML files in the ZIP file
          you upload into individual KMZ files.
        </p>
        <ol>
          <li>
            Select the ZIP file that contains the KML files you want to convert
            from the input field on the page. This ZIP file can contain one or
            more KML files.
          </li>
          <li>Next, click the "Convert" button.</li>
        </ol>
        <p>Once you do this, the following process occurs:</p>
        <ol>
          <li>
            The selected ZIP file is opened, and all KML files contained in it
            are converted into individual KMZ files. For example, if there was a
            file called "data.kml" in the original ZIP file, it is converted
            into a new KMZ file called "data.kmz".
          </li>
          <li>
            Once all KML files are converted into individual KMZ files, all
            these new KMZ files are combined into a single ZIP file. This final
            ZIP file is automatically downloaded. The name of this download file
            is "newZip.zip".
          </li>
        </ol>
        <p>
          Please note that this tool works entirely in the browser and does not
          require any server-side processing. However, if you upload a very
          large ZIP file, it may take time to process or slow down the browser.
        </p>
      </div>{" "}
      <input
        type="file"
        id="zip-file"
        ref={fileInputRef}
        disabled={isProcessing}
      />
      <button id="convert" onClick={convertFiles} disabled={isProcessing}>
        Convert
      </button>
      {isProcessing && <div className="progressBar">Processing...</div>}
    </div>
  );
};

export default KMLtoKMZConverter;
