import React, { useState, useRef } from "react";
import Papa from "papaparse";

export default function CsvColumnReorder() {
  const [columns, setColumns] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);

  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setColumns(Object.keys(results.data[0])); // Set column headers
        setOriginalData(results.data); // Save the original data for processing later
      },
    });
  };

  const handleDragStart = (index) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index) => (event) => {
    event.preventDefault();
    if (index === draggedItem) return;

    const newColumns = Array.from(columns);
    const itemDragged = newColumns.splice(draggedItem, 1)[0];
    newColumns.splice(index, 0, itemDragged);

    setDraggedItem(index);
    setColumns(newColumns);
  };

  const downloadCSV = () => {
    const reorderedData = originalData.map((row) => {
      return columns.reduce((result, key) => {
        result[key] = row[key] || "";
        return result;
      }, {});
    });

    const csv = Papa.unparse(reorderedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "reordered_columns.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-4">
        CSVの列を並び替えるツール
      </h1>
      <p className="text-md text-gray-600 text-center mb-6">
        このツールを使用して、CSVファイルの列の順序をドラッグ&ドロップで簡単に並び替え、その結果を新しいCSVファイルとしてダウンロードできます。
      </p>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          CSVファイルを選択してください：
        </label>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">
          列をドラッグして並び替え：
        </label>
        <ul className="list-none p-0">
          {columns.map((col, index) => (
            <li
              key={index}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver(index)}
              className="bg-white p-2 mb-1 cursor-pointer shadow-lg"
            >
              {col}
            </li>
          ))}
        </ul>
        <button
          onClick={downloadCSV}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
        >
          並び替えたCSVをダウンロード
        </button>
      </div>
    </div>
  );
}
