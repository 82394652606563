import React, { useState } from 'react';

const Conv3rdto2nd = () => {
  const [decimalPlaces, setDecimalPlaces] = useState(2);

  const handleDecimalChange = (event) => {
    setDecimalPlaces(event.target.value);
  };

  return (
    <div style={styles.container}>
      <h4 style={styles.header}>1. aaa</h4>
      <div>
        <label htmlFor="aggregateOption" style={styles.label}>集計オプション：</label>
        <select id="aggregateOption" style={styles.input}>
          <option value="sum">2列目の合計値、3列目の合計値</option>
          <option value="max">2列目の合計値、3列目の最大値</option>
          <option value="min">2列目の合計値、3列目の最小値</option>
          <option value="avg">2列目の合計値、3列目の平均値</option>
          <option value="avgAvg">2列目の平均値、3列目の平均値</option>
        </select>
      </div>

      <h4 style={styles.header}>2. bbb</h4>
      <div>
        <label htmlFor="decimalPlaces" style={styles.label}>小数点以下の最大桁数：</label>
        <input
          type="number"
          id="decimalPlaces"
          value={decimalPlaces}
          onChange={handleDecimalChange}
          min="0"
          max="10"
          step="1"
          style={styles.input}
        />
      </div>

      <h4 style={styles.header}>3. ccc</h4>
      <div id="dropZone" style={styles.dropZone}>ファイルをここにドラッグ＆ドロップ</div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    background: 'linear-gradient(135deg, #806642, #59482e 50%, #806642)',
    color: '#f1e6d3',
    padding: '20px',
  },
  header: {
    color: '#f1e6d3',
    marginBottom: '10px',
  },
  label: {
    color: '#f1e6d3',
    marginRight: '10px',
  },
  input: {
    backgroundColor: '#e6d4c0',
    border: '1px solid #c4a689',
    padding: '5px',
    borderRadius: '5px',
    fontSize: '14px',
    color: '#5a432e',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    cursor: 'pointer',
  },
  dropZone: {
    backgroundColor: '#f1e6d3',
    border: '2px dashed #c4a689',
    borderRadius: '5px',
    color: '#5a432e',
    textAlign: 'center',
    padding: '20px',
    marginTop: '10px',
    fontSize: '16px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  }
};

export default Conv3rdto2nd;
