import "./App.css";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PasswordInput from "./PasswordInput";
import StringProcessor from "./StringProcessor";
import DownloadFilesComponent from "./DownloadFilesComponent";
import Txt2csv from "./TXTtoCSV";
import Home from "./Home";
import CSVtoZIPConverter from "./CSVtoZIPConverter";
import KMLtoKMZConverter from "./KMLtoKMZConverter";
import CSVJoinComponent from "./CSVJoinComponent";
import Conv3rdto2nd from "./conv3rdto2nd";
import ShindoConv from "./shindoconv";
import CsvDensity from "./csvdensity";
import CsvMatcher from "./CsvMatcher";
import CSVProcessor from "./CSVProcessor";
import CsvMatchChecker from "./CsvMatchChecker";
import UchiMatsuReader from "./UchiMatsuReader";
import GeoJsonToCsvConverter from "./GeojsonToCsvConverter";
import CSVExtractor from "./CSVExtractor";
import CsvColumnReorder from "./CsvColumnReorder";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const correctPassword = "kashika"; // 正しいパスワードを設定

  const handleLogin = (password) => {
    if (password === correctPassword) {
      setIsLoggedIn(true);
    } else {
      alert("パスワードが間違っています！");
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          都市構造可視化推進機構会員専用ミニツール
          <br />
          （パスワードの再発行は事務局までご連絡ください）
        </p>
      </header>

      {!isLoggedIn ? (
        <PasswordInput onLogin={handleLogin} />
      ) : (
        <div>
          <h1>ログイン成功！</h1>

          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Home />
                  </>
                }
              />
              <Route path="/CsvColumnReorder" element={<CsvColumnReorder />} />
              <Route path="/CSVExtractor" element={<CSVExtractor />} />
              <Route
                path="/GeojsonToCsvConverter"
                element={<GeoJsonToCsvConverter />}
              />
              <Route path="/UchiMatsuReader" element={<UchiMatsuReader />} />
              <Route path="/CsvMatcher" element={<CsvMatcher />} />
              <Route path="/CsvMatchChecker" element={<CsvMatchChecker />} />

              <Route path="/csvdensity" element={<CsvDensity />} />
              <Route path="/shindoconv" element={<ShindoConv />} />
              <Route path="/conv3rdto2nd" element={<Conv3rdto2nd />} />
              <Route path="/txt2csv" element={<Txt2csv />} />
              <Route path="/stringprocessor" element={<StringProcessor />} />
              <Route
                path="/downloadfiles"
                element={<DownloadFilesComponent />}
              />
              <Route
                path="/csvtozipconverter"
                element={<CSVtoZIPConverter />}
              />
              <Route
                path="/kmltokmzconverter"
                element={<KMLtoKMZConverter />}
              />
              <Route path="/csvjoin" element={<CSVJoinComponent />} />
              <Route path="/csvprocesser" element={<CSVProcessor />} />
            </Routes>
          </Router>
        </div>
      )}
      <footer></footer>
    </div>
  );
}

export default App;
