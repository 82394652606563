import React, { useState } from 'react';

function PasswordInput({ onLogin }) {
  const [password, setPassword] = useState('');

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onLogin(password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        パスワード:
        <input type="password" value={password} onChange={handleChange} />
      </label>
      <button type="submit">ログイン</button>
    </form>
  );
}

export default PasswordInput;
