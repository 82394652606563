import React, { useState } from 'react';

const DownloadFilesComponent = () => {
    const [textAreaValue1, setTextAreaValue1] = useState(localStorage.getItem('urls1') || 'ダウンロードしたいURLを改行で区切って入力してください');
    const [textAreaValue2, setTextAreaValue2] = useState(localStorage.getItem('urls2') || 'ダウンロードしたいURLを改行で区切って入力してください');
    const [textAreaValue3, setTextAreaValue3] = useState(localStorage.getItem('urls3') || 'ダウンロードしたいURLを改行で区切って入力してください');

    const downloadFiles = (urls) => {
        urls.forEach((url, index) => {
            setTimeout(() => {
                let link = document.createElement('a');
                link.href = url;
                link.download = url.split("/").pop();
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }, index * 1000);
        });
    };

    const handleButtonClick = (urls, setFunction, localStorageKey) => {
        let urlsArray = urls.split('\n');
        downloadFiles(urlsArray);
        localStorage.setItem(localStorageKey, urls);
        setFunction(urls);
    };

    return (
        <div>
            <textarea
                value={textAreaValue1}
                onChange={event => setTextAreaValue1(event.target.value)}
                rows="4"
                cols="50"
            />
            <button onClick={() => handleButtonClick(textAreaValue1, setTextAreaValue1, 'urls1')}>Download Files 1</button>

            <textarea
                value={textAreaValue2}
                onChange={event => setTextAreaValue2(event.target.value)}
                rows="4"
                cols="50"
            />
            <button onClick={() => handleButtonClick(textAreaValue2, setTextAreaValue2, 'urls2')}>Download Files 2</button>

            <textarea
                value={textAreaValue3}
                onChange={event => setTextAreaValue3(event.target.value)}
                rows="4"
                cols="50"
            />
            <button onClick={() => handleButtonClick(textAreaValue3, setTextAreaValue3, 'urls3')}>Download Files 3</button>
        </div>
    );
};

export default DownloadFilesComponent;

// プログラム説明書
// 概要
// このプログラムは、ユーザーが指定した複数のURLからファイルを自動的にダウンロードするためのJavaScriptを使用したHTMLページです。ユーザーはテキストエリアにダウンロードしたいファイルのURLを入力し、ボタンをクリックすることでダウンロードが開始されます。さらに、入力されたURLはlocalStorageに保存され、次回のページロード時にも利用可能です。
// 使用方法
// HTMLページをブラウザで開きます。
// ページ内のテキストエリアに、ダウンロードしたいURLを1行に1つずつ入力します。入力可能なテキストエリアは3つあります。
// 入力したURLからファイルをダウンロードするには、対応する「Download Files 1」、「Download Files 2」、「Download Files 3」ボタンをクリックします。
// ボタンをクリックすると、入力されたURLからファイルがダウンロードされます。また、入力されたURLはlocalStorageに保存されます。
// ページをリロードすると、以前に入力したURLがテキストエリアに表示されます。
// 注意事項
// このプログラムの動作は同一オリジンポリシー、CORS設定、そして各ブラウザのセキュリティ設定に依存します。そのため、全ての環境や全てのURLで期待通りに動作するわけではない可能性があります。
// ダウンロードの時間間隔は1秒ごとに設定されています。これはネットワークやサーバーに負荷をかけすぎないための措置です。
// ブラウザやオペレーティングシステムの設定により、複数のファイルのダウンロード許可を求められることがあります。
