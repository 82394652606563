import React, { useState } from "react";
import Papa from "papaparse";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import JSZip from "jszip";

function CsvMatcher() {
  const [filesA, setFilesA] = useState([]);
  const [fileB, setFileB] = useState(null);
  const [processedFiles, setProcessedFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (file, setter) => {
    setter(file);
  };

  const handleFilesAChange = (files) => {
    setFilesA([...files]);
  };

  const processFiles = async () => {
    if (filesA.length > 0 && fileB) {
      setLoading(true);
      NProgress.start();

      const processedFilesArray = await Promise.all(
        filesA.map(async (file) => {
          const resultsA = await parseFile(file);
          const resultsB = await parseFile(fileB);

          const dataA = resultsA.data;
          const dataB = resultsB.data;
          const mapB = new Map(dataB.map((row) => [row[0], row[1]]));
          const matchedData = dataA
            .filter((row) => mapB.has(row[0]))
            .map((row) => [row[0], row[1], mapB.get(row[0])]);
          const csv = Papa.unparse(matchedData);
          return csv;
        })
      );

      setProcessedFiles(processedFilesArray);
      NProgress.done();
      setLoading(false);
    }
  };

  const parseFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: resolve,
        error: reject,
      });
    });
  };

  const downloadCsvsAsZip = async () => {
    if (processedFiles && processedFiles.length > 0) {
      const zip = new JSZip();
      processedFiles.forEach((csv, index) => {
        const fileName = `${filesA[index].name}_${fileB.name}`; // ファイル名の生成ルール
        zip.file(fileName, csv); // 各ファイルを指定した名前で追加
      });
      const content = await zip.generateAsync({ type: "blob" });

      const url = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "output.zip");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div>
      <input
        type="file"
        onChange={(e) => handleFilesAChange(e.target.files)}
        multiple
      />
      <input
        type="file"
        onChange={(e) => handleFileChange(e.target.files[0], setFileB)}
      />
      <button onClick={processFiles} disabled={loading}>
        Process Files
      </button>
      {processedFiles && (
        <button onClick={downloadCsvsAsZip}>
          Download Processed CSVs as ZIP
        </button>
      )}
    </div>
  );
}

export default CsvMatcher;
