import React, { useState, useEffect } from "react";

// 使い方
// import StringProcessor from './StringProcessor';

const StringProcessor = () => {
  const [pairs, setPairs] = useState([]);
  const [csvInput, setCsvInput] = useState("");
  const [result, setResult] = useState("");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data")) || [];
    const storedCsvInput = localStorage.getItem("csvInput") || "";
    setPairs(data);
    setCsvInput(storedCsvInput);
  }, []);

  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(pairs));
    localStorage.setItem("csvInput", csvInput);
  }, [pairs, csvInput]);

  const processData = () => {
    const csvLines = csvInput.split("\n");
    let newResult = "";

    for (let i = 0; i < csvLines.length; i++) {
      for (let j = 0; j < pairs.length; j++) {
        const { prefix, suffix } = pairs[j];
        if (csvLines[i].trim() !== "") {
          newResult += `${prefix}${csvLines[i].trim()}${suffix}\n`;
        }
      }
    }

    setResult(newResult);
  };

  const addPair = () => {
    setPairs([...pairs, { prefix: "", suffix: "" }]);
  };

  const deletePair = (index) => {
    setPairs(pairs.filter((_, i) => i !== index));
  };

  const updatePair = (index, prefix, suffix) => {
    const newPairs = [...pairs];
    newPairs[index] = { prefix, suffix };
    setPairs(newPairs);
  };

  return (
    <div className="container">
              <textarea
                className="csv-textarea"
                value={csvInput}
                onChange={(e) => setCsvInput(e.target.value)}
              />
      <div className="pairs">
        {pairs.map((pair, index) => (
          <div key={index} className="pair">
            <input
              className="prefix-input"
              value={pair.prefix}
              onChange={(e) => updatePair(index, e.target.value, pair.suffix)}
            />
            <input
              className="suffix-input"
              value={pair.suffix}
              onChange={(e) => updatePair(index, pair.prefix, e.target.value)}
            />
            <button onClick={() => deletePair(index)} className="delete-btn">
              ペア削除
            </button>
          </div>
        ))}
        <button onClick={addPair} className="add-btn">
          ペア追加
        </button>
      </div>
      <button onClick={processData} className="process-btn">
        処理
      </button>
      <textarea value={result} readOnly className="result-area" />
    </div>
  );
};

export default StringProcessor;

// 文字列処理ウェブページ
// このウェブページは、指定した接頭辞と接尾辞を使って、入力されたCSV形式のデータに基づいて新しい文字列を生成します。各行のCSVデータに対して、一連の接頭辞と接尾辞ペアを適用します。ページから入力できる接頭辞と接尾辞ペアの数は任意に設定可能です。
// 使用方法
// 「ペア追加」ボタンをクリックして、新しい接頭辞と接尾辞のペアを追加します。各ペアには自動的に「ペア削除」ボタンが追加され、それをクリックするとペアが削除されます。
// 各ペアに対して、必要な接頭辞と接尾辞を入力します。
// CSV形式のデータを「CSV入力」テキストエリアに入力またはペーストします。各行が新しいデータとして処理されます。
// 「処理」ボタンをクリックします。これにより、各行のCSVデータに対して指定した接頭辞と接尾辞を適用した新しい文字列が生成され、「結果」テキストエリアに表示されます。
// 追加の機能
// データの保存: すべての入力（接頭辞、接尾辞、CSVデータ）は自動的にローカルストレージに保存されます。ページをリロードしても入力は保持されます。
// データの読み込み: ページが読み込まれると、ローカルストレージから保存されたデータ（接頭辞、接尾辞、CSVデータ）が自動的に読み込まれます。
