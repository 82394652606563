import React, { useState } from "react";

// CSSをJavaScriptオブジェクトとして定義
const styles = {
  bodyStyle: {
    fontFamily: "Arial, sans-serif",
    margin: 0,
    padding: "5%",
    boxSizing: "border-box",
  },
  headerColor: {
    color: "#333",
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  columnStyle: {
    flex: 1,
    padding: "10px",
    boxSizing: "border-box",
  },
  textareaStyle: {
    width: "100%",
    boxSizing: "border-box",
  },
  preStyle: {
    background: "#f8f8f8",
    padding: "10px",
  },
};

const Txt2csv = () => {
  const [inputData, setInputData] = useState("");
  const [inputConfig, setInputConfig] = useState("");
  const [outputData, setOutputData] = useState("");

  const processData = () => {
    var dataLines = inputData.split("\n");
    var configLines = inputConfig.split("\n");

    var setLines = parseInt(configLines[0]);

    var outputLines = [];
    var outputHeaders = Array(configLines.length - 1).fill("");

    for (var j = 1; j < configLines.length; j++) {
      var configParts = configLines[j].split(",");
      var outputCol = parseInt(configParts[0]);
      outputHeaders[outputCol - 1] = configParts[1];
    }

    outputLines.push(outputHeaders.join(","));

    for (var i = 0; i < dataLines.length; i += setLines) {
      var outputLine = Array(configLines.length - 1).fill("");

      for (var j = 1; j < configLines.length; j++) {
        var configParts = configLines[j].split(",");
        var outputCol = parseInt(configParts[0]);
        var dataLine = parseInt(configParts[2]);
        outputLine[outputCol - 1] = dataLines[i + dataLine - 1];
      }

      outputLines.push(outputLine.join(","));
    }

    setOutputData(outputLines.join("\n"));
  };

  return (
    <div style={styles.bodyStyle}>
      <h1 style={styles.headerColor}>
        １列データから必要な行を抽出しCSVを作成するツール
      </h1>
      <h2 style={styles.headerColor}>1. データ入力</h2>
      <p>
        右のテキストエリアに、抽出したいデータをCSV形式で入力します。複数行のデータが許容されており、特定の行数ごとに1セットのデータとして扱われます。
      </p>
      <div style={styles.rowStyle}>
        <div style={styles.columnStyle}>
          例：以下のようなデータを入力します。
          <pre style={styles.preStyle}>
            Name1<br/>Age1<br/>Address1<br/>Phone1<br/>Name2<br/>Age2<br/>Address2<br/>Phone2
          </pre>
        </div>
        <div style={styles.columnStyle}>
          <textarea
            id="inputData"
            rows="10"
            style={styles.textareaStyle}
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
          />
        </div>
      </div>
      <h2 style={styles.headerColor}>2. 処理設定入力</h2>
      <p>
        右のテキストエリアに、抽出ルールをCSV形式で入力します。1行目にはデータの1セットに含まれる行数を記載します。2行目以降には、出力する列番号、その列のヘッダー名、その列に対応する入力データの行数をカンマ区切りで記載します。
      </p>
      <div style={styles.rowStyle}>
        <div style={styles.columnStyle}>
          例：以下のような設定を入力します。
          <pre style={styles.preStyle}>4<br/>1,Name,1<br/>2,Age,2</pre>
        </div>
        <div style={styles.columnStyle}>
          <textarea
            id="inputConfig"
            rows="10"
            style={styles.textareaStyle}
            value={inputConfig}
            onChange={(e) => setInputConfig(e.target.value)}
          />
        </div>
      </div>
      <h2 style={styles.headerColor}>3. 処理開始</h2>
      <p>
        処理ボタンをクリックします。この操作により、入力データが設定に従って抽出・整形されます。
      </p>
      <div style={styles.rowStyle}>
        <div style={styles.columnStyle} />
        <div style={styles.columnStyle}>
          <button onClick={processData}>処理開始</button>
        </div>
      </div>
      <h2 style={styles.headerColor}>4. データ出力</h2>
      <p>抽出・整形した結果が、テキストエリア（D）にCSV形式で出力されます。</p>
      <div style={styles.rowStyle}>
        <div style={styles.columnStyle}>
          例：以下のようなデータが出力されます。
          <pre style={styles.preStyle}>Name,Age<br/>Name1,Age1<br/>Name2,Age2</pre>
        </div>
        <div style={styles.columnStyle}>
          <textarea
            id="outputData"
            rows="10"
            style={styles.textareaStyle}
            value={outputData}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default Txt2csv;
