import React, { useState } from "react";
import Papa from "papaparse";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import JSZip from "jszip";

function CsvMatcher() {
  const [filesA, setFilesA] = useState([]);
  const [fileB, setFileB] = useState(null);
  const [processedFiles, setProcessedFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (file, setter) => {
    setter(file);
  };

  const handleFilesAChange = (files) => {
    setFilesA([...files]);
  };

  const processFiles = async () => {
    if (filesA.length > 0 && fileB) {
      setLoading(true);
      NProgress.start();

      const processedFilesArray = await Promise.all(
        filesA.map(async (file) => {
          const resultsA = await parseFile(file);
          const resultsB = await parseFile(fileB);

          const dataA = resultsA.data;
          const dataB = resultsB.data;
          const mapB = new Map(dataB.map((row) => [row[0], row[1]]));
          const matchedData = dataA
            .filter((row) => mapB.has(row[0]))
            .map((row) => {
              const value = row[1] / mapB.get(row[0]);
              const roundedValue = Math.round(value * 100) / 100; // 3桁を四捨五入し、小数点以下2桁までに制限
              return [row[0], row[1], roundedValue];
            });
          const csv = Papa.unparse(matchedData);
          return csv;
        })
      );

      setProcessedFiles(processedFilesArray);
      NProgress.done();
      setLoading(false);
    }
  };

  const parseFile = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: resolve,
        error: reject,
      });
    });
  };

  const downloadCsvsAsZip = async () => {
    if (processedFiles && processedFiles.length > 0) {
      const zip = new JSZip();
      processedFiles.forEach((csv, index) => {
        const fileName = `${filesA[index].name}_${fileB.name}`;
        zip.file(fileName, csv);
      });
      const content = await zip.generateAsync({ type: "blob" });

      const url = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "output.zip");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <>
      <div>
        <div className="p-8 max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold text-indigo-800 mb-6">
            2つの2列CSVを統合して割り算を行い3列CSVを作成するツール
          </h2>

          <div className="mb-8">
            <h3 className="text-lg font-semibold text-gray-900 mb-2">概要</h3>
            <p className="text-gray-600">
              CSVファイルを処理し、特定の条件に基づいてマッチングしてからダウンロード可能なZIPファイルにまとめるためのツールです。
            </p>
          </div>

          <div class="space-y-8">
            <div>
              <label
                htmlFor="filesA"
                class="block text-sm font-medium text-gray-700"
              >
                2列データCSVをアップロードします（複数ファイル可）:
              </label>
              <div class="flex items-center space-x-3">
                <input
                  id="filesA"
                  type="file"
                  onChange={(e) => handleFilesAChange(e.target.files)}
                  multiple
                  class="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                <a
                  href="/sampledata/012015002.csv"
                  download
                  class="text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out"
                >
                  サンプルデータ1
                </a>
                <a
                  href="/sampledata/012015003.csv"
                  download
                  class="text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out"
                >
                  サンプルデータ2
                </a>
              </div>
            </div>

            <div>
              <label
                htmlFor="fileB"
                class="block text-sm font-medium text-gray-700"
              >
                面積CSVをアップロードします:
              </label>
              <div class="flex items-center space-x-3">
                <input
                  id="fileB"
                  type="file"
                  onChange={(e) =>
                    handleFileChange(e.target.files[0], setFileB)
                  }
                  class="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                <a
                  href="/sampledata/4次メッシュの面積ha.csv"
                  download
                  class="text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out"
                >
                  4次メッシュの面積
                </a>
                <a
                  href="/sampledata/3次メッシュの面積ha.csv"
                  download
                  class="text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out"
                >
                  3次メッシュの面積
                </a>
              </div>
            </div>

            <button
              onClick={processFiles}
              disabled={loading}
              class="w-full flex justify-center py-3 px-6 border border-transparent rounded-lg shadow-md text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 disabled:bg-gray-400"
            >
              処理を開始する
            </button>

            {processedFiles && (
              <button
                onClick={downloadCsvsAsZip}
                class="w-full flex justify-center py-3 px-6 border border-transparent rounded-lg shadow-md text-sm font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
              >
                Download Processed CSVs as ZIP
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CsvMatcher;
